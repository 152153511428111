<template>
  <div class="form-grid form-grid-col">
    <div class="form-grid-item">
      <span class="form-grid-item-title">E-Mail-Adresse*</span>

      <span class="form-grid-item-value">
        <input-regular
          v-model="fieldsAuth.email.value"
          type="email"
          :showLabel="false"
          :validation="fieldsAuth.email"
          @enterPress="handleSubmit"
          @errorExist="passValid('email', $event)"
        />
      </span>
    </div>

    <div class="form-grid-item">
      <span class="form-grid-item-title">Kennwort*</span>

      <span class="form-grid-item-value">
        <input-regular
          v-model="fieldsAuth.password.value"
          type="password"
          :showLabel="false"
          :validation="fieldsAuth.password"
          @enterPress="handleSubmit"
          @errorExist="passValid('password', $event)"
        />
      </span>
    </div>

    <div class="login-button">
      <button-regular
        :isLoading="buttonLoading"
        class="button-accent"
        @click="handleSubmit"
      >
        Jetzt Konto verbinden
      </button-regular>
    </div>

    <router-link
      v-if="currentFlow === 'offer_order'"
      :to="{ name: 'Danke' }"
      class="link text-center"
    >
      Überspringen und Konto zu einem späteren Zeitpunkt verbinden.
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fieldsAuth, validateAuthFields } from '@/placeholder/fieldsValidation';
import InputRegular from '@/components/next/input/input-regular.vue';
import ButtonRegular from '@/components/next/button-regular.vue';

export default {
  components: { InputRegular, ButtonRegular },

  props: ['userEmail', 'getRequestData', 'approveMail'],

  data: () => ({
    fieldsAuth,
    validateAuthFields,
    auth: {},
    buttonLoading: false,
    errorsNotExist: true
  }),

  computed: {
    ...mapState('user', ['offerInputHistory', 'currentFlow'])
  },

  methods: {
    clearError(field) {
      this.fieldsAuth[field].error = '';
      this.fieldsAuth[field].valid = true;
    },

    passValid(field, event) {
      this.fieldsAuth[field] = event;
    },

    handleSubmit() {
      this.buttonLoading = true;

      if (!this.validationFields()) {
        this.buttonLoading = false;

        return;
      }

      this.authUser();
    },

    validationFields() {
      this.errorsNotExist = true;

      this.validateAuthFields.forEach(field => {
        this.clearError(field);

        if (this.fieldsAuth[field].value === '') {
          this.fieldsAuth[field].valid = false;
          this.fieldsAuth[field].error = 'Dieses Feld muss angegeben werden';
          this.errorsNotExist = false;
        }
      });

      return this.errorsNotExist;
    },

    authUser() {
      const data = this.getRequestData(false);

      this.$store
        .dispatch('user/USER_AUTH', data)
        .then(res => {
          this.$store.commit('user/SET_ACCESS_TOKEN', res.data.token);
          this.auth = { response: res.data.success };

          if (!res.data.success) {
            this.handleAuthError();

            return;
          }

          this.validateAuthFields.forEach(field => {
            this.clearError(field);
          });

          this.$emit('updateUserEmail', res.data.email);

          this.$nextTick(() => {
            this.checkFlow();
          });
        })
        .catch(() => {
          this.auth = { response: false };
          this.handleAuthError();
          this.buttonLoading = false;
        });
    },

    handleAuthError() {
      this.fieldsAuth.password.error = 'Falscher Benutzername oder Passwort';
      this.fieldsAuth.password.valid = false;
    },

    checkFlow() {
      let checkArg = null;

      if (this.currentFlow === 'offer_order') {
        checkArg = this.offerInputHistory.email;
      } else if (this.currentFlow === 'mr_dashboard') {
        checkArg = this.userEmail;
      }

      if (!this.checkUserDataHistory(checkArg)) {
        this.$emit('changeModalVisibility', true);

        this.buttonLoading = false;
      } else {
        this.approveMail();
      }
    },

    checkUserDataHistory(val) {
      return val === this.fieldsAuth.email.value;
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
