<template>
  <div id="modal">
    <transition name="fade" appear>
      <div
        v-if="showModal"
        class="modal-overlay"
        @click="$emit('closeModal', false)"
      ></div>
    </transition>

    <transition name="pop" appear>
      <div
        v-if="showModal"
        role="dialog"
        class="modal"
        :class="[{ 'modal-active': showModal }, classAdd]"
      >
        <slot>Modal Fallback</slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    classAdd: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
