<template>
  <div v-if="currentFlow === 'offer_order'" class="heading-col">
    <h1 class="color-primary-100">Noch ein wichtiger Schritt</h1>

    <p class="text-subtitle color-accent-300">
      Wir müssen Ihr neues Strompakete-Produkt noch mit Ihrem bestehenden Konto
      bei „mein eprimo“ verbinden.
    </p>

    <small class="text-normal text-sub-subtitle color-accent-300">
      Bitte geben Sie hierfür einmalig die E-Mail-Adresse und das Kennwort ein,
      die Sie für den Zugang zum Kundenbereich „mein eprimo“ verwenden. Wir
      ordnen das Strompakete-Produkt so Ihrem Konto zu. Vielen Dank!
    </small>
  </div>

  <div v-else class="heading-col">
    <h1 class="color-primary-100">Bitte melden Sie sich an</h1>

    <p class="text-subtitle color-accent-300">
      Bitte melden Sie sich mit Ihren Zugangsdaten für das eprimo Kundenportal
      an. Wir nutzen diese Daten bei der ersten Eingabe, um Ihr Strompakete
      Konto mit Ihrem eprimo Kundenportal-Konto zu verbinden. Falls Sie Ihre
      Zugangsdaten vergessen haben und das Kennwort zurücksetzen möchten oder
      noch kein Kundenportal-Konto erstellt haben, können Sie dies hier
      erledigen:

      <a href="https://www.eprimo.de/anmelden" target="_blank">
        eprimo.de/anmelden
      </a>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('user', ['currentFlow'])
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
