<template>
  <main id="auth-next" class="next-container auth-page">
    <section-base>
      <auth-user-heading />

      <auth-user-form
        :approveMail="approveMail"
        :getRequestData="getRequestData"
        :userEmail="userEmail"
        @updateUserEmail="userEmail = $event"
        @changeModalVisibility="showModal = $event"
      />
    </section-base>

    <auth-user-modal
      :userEmail="userEmail"
      :approveMail="approveMail"
      :showModal="showModal"
      @changeModalVisibility="showModal = $event"
    />
  </main>
</template>

<script>
import AuthUserHeading from '../../components/next/auth-user-heading.vue';
import AuthUserModal from '../../components/next/auth-user-modal.vue';
import AuthUserForm from '../../components/next/auth-user-form.vue';
import { mapState } from 'vuex';
import { fieldsAuth } from '@/placeholder/fieldsValidation';
import SectionBase from '@/components/next/section-base.vue';

export default {
  components: { AuthUserModal, AuthUserForm, AuthUserHeading, SectionBase },

  data: () => ({
    userEmail: '',
    fieldsAuth,
    showModal: false
  }),

  computed: {
    ...mapState('user', ['offerInputHistory', 'currentFlow'])
  },

  methods: {
    approveMail() {
      const data = this.getRequestData(true);

      this.$store.dispatch('user/USER_AUTH', data).then(res => {
        if (!res.data.success) {
          return;
        }

        this.showModal = false;

        if (this.currentFlow === 'offer_order') {
          this.$router.push({ name: 'Danke' });
        } else if (this.currentFlow === 'mr_dashboard') {
          this.$store.commit('user/SET_DASHBOARD_VISIBILITY', true);

          this.passToMeterReading(res.data.uuid);
        }
      });
    },

    getRequestData(consent) {
      return {
        email: this.fieldsAuth.email.value,
        password: this.fieldsAuth.password.value,
        offerUuid:
          this.currentFlow === 'offer_order'
            ? this.offerInputHistory.offer_id
            : null,
        userUuid:
          this.currentFlow === 'mr_dashboard'
            ? this.$route.params.uuid ?? null
            : null,
        consent: consent
      };
    },

    passToMeterReading(uuid) {
      this.$store.dispatch('user/GET_METER_READING_DATA', {
        uuid: uuid ?? this.$route.params.uuid,
        push: true
      });
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
