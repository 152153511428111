<template>
  <modal-window :showModal="showModal" @closeModal="closeApproveModal">
    <div class="modal-body auth-modal">
      <auth-user-modal-content :userEmail="userEmail" />

      <div class="modal-button-block">
        <button-regular class="button-accent" @click="approveMail">
          OK
        </button-regular>

        <button-regular class="button-cancel" @click="closeApproveModal">
          Absagen
        </button-regular>
      </div>
    </div>
  </modal-window>
</template>

<script>
import AuthUserModalContent from '../../components/next/auth-user-modal-content.vue';
import ModalWindow from '@/components/next/modal-window.vue';
import ButtonRegular from '@/components/next/button-regular.vue';

export default {
  components: { AuthUserModalContent, ModalWindow, ButtonRegular },

  props: ['userEmail', 'approveMail', 'showModal'],

  methods: {
    closeApproveModal() {
      this.$emit('changeModalVisibility', false);
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
