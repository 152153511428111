<template>
  <p>
    Sie nutzen eine andere E-Mailadresse zum Zugriff auf Ihr eprimo
    Kundenportal-Konto. Sie müssen die Adresse

    <span class="color-primary-100">
      {{ fieldsAuth.email.value }}
    </span>

    {{
      isOfferOrderFlow
        ? 'auch für die Strompakete nutzen und die eingegebene Adresse'
        : 'auch zur Anmeldung für das Dashboard nutzen. Die alte Adresse'
    }}

    <span class="color-primary-100">
      {{ isOfferOrderFlow ? offerInputHistory.email : userEmail }}
    </span>

    wird durch

    <span class="color-primary-100">
      {{ fieldsAuth.email.value }}
    </span>

    ersetzt. Alle zukünftige Kommunikation wird über die Adresse

    <span class="color-primary-100">
      {{ fieldsAuth.email.value }}
    </span>

    stattfinden
  </p>
</template>

<script>
import { mapState } from 'vuex';
import { fieldsAuth } from '@/placeholder/fieldsValidation';

export default {
  props: ['userEmail'],

  data: () => ({
    fieldsAuth
  }),

  computed: {
    ...mapState('user', ['currentFlow', 'offerInputHistory']),

    isOfferOrderFlow() {
      return !this.currentFlow === 'offer_order';
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
